import Loadable from "@/entities/Loadable";

export default class BatchSummary extends Loadable
{
    total;
    total_delivered;
    week;
    delivery;

    statuses;
    constructor(data) {
        super();
        this.total = data.total;
        this.total_delivered = data.total_delivered;
        this.week = data.week;
        this.delivery = data.delivery;
        this.statuses = data.statuses;
    }

    getTotal()
    {
        return this.total;
    }

    getTotalDelivered()
    {
        return this.total_delivered;
    }

    getWeek()
    {
        return this.week;
    }

    getDelivery()
    {
        return this.delivery;
    }

    getFlattenStatusValues()
    {
        return Object.values(this.statuses);
    }
}