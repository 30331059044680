<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <Card :with-footer="canCleanupApplication" title="dashboard.title">
    <template v-slot:default>
      <div class="row">
        <div class="col-12 col-lg-4 mb-2 mb-lg-0">
          <dl class="row mb-0 font-size-lg">
            <dt class="col-8 col-lg-6 mb-1">{{$t('dashboard.created_orders')}}</dt>
            <dd class="col-4 col-lg-6 mb-1">
              <DayTimeEstimation :moment="activeCycle && activeCycle.getOrderBatch()" />
            </dd>
            <dt class="col-8 col-lg-6 mb-1">{{$t('dashboard.delivery_orders')}}</dt>
            <dd class="col-4 col-lg-6 mb-1">
              <DayTimeEstimation :moment="activeCycle && activeCycle.getDeliverOrder()" />
            </dd>
          </dl>
        </div>
        <div class="col-12 col-lg-4 text-left">
          <Qty :amount="orderSummary ? orderSummary.getCycleWeek() : 0" :unit="$t('dashboard.total_orders_current_cycle')"/>
          <Qty :amount="orderSummary ? orderSummary.getTotalDelivered() : 0" :unit="$t('dashboard.total_delivered_orders')"/>
        </div>
        <div class="col-12 col-lg-4">
          <Qty :amount="orderSummary ? orderSummary.getWeek() : 0" :unit="$t('dashboard.total_orders_current_week')"/>
          <Qty :amount="batchSummary ? batchSummary.getTotalDelivered() : 0" :unit="$t('dashboard.total_delivered_batches')"/>
        </div>
      </div>
      <hr class="content-divider mt-4 mb-4" />
      <div class="row">
        <div class="text-center col-12">
          <h2 class="verde-title font-weight-semibold">{{$t('dashboard.weekly_charts')}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <vue-echarts :option="orders" class="charts" ref="orderChart" />
        </div>
        <div class="col-12 col-lg-6 mt-4 mt-lg-0">
          <vue-echarts :option="batches" class="charts" ref="batchChart" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Button class="mr-2" @click="askConfirmation('general.cleanup')" type="delete" name="general.buttons.cleanup"/>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import Qty from "@/components/elements/Qty";
import {VueEcharts} from 'vue3-echarts';
import {ConfirmationModalMixin, LoadingModalMixin} from "@/mixins/ModalMixin";
import OrderSummary from "@/entities/OrderSummary";
import BatchSummary from "@/entities/BatchSummary";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";
import {AuthorizedActionsMixin} from "@/mixins/GeneralMixin";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import Button from "@/components/elements/Button";

export default {
  name: "Dashboard",
  components: {ConfirmationModal, DayTimeEstimation, Qty, Card, VueEcharts, Button},
  mixins: [LoadingModalMixin, AuthorizedActionsMixin, ConfirmationModalMixin],
  data: function () {
    return {
      orderSummary: null,
      batchSummary: null,
      orders: {
        // Chart animation duration
        animationDuration: 750,

        // Setup grid
        grid: {
          left: 5,
          right: 30,
          top: 35,
          bottom: 0,
          containLabel: true
        },

        // Add legend
        legend: {
          data: [this.$t('dashboard.customer_orders')],
          itemHeight: 8,
          itemGap: 20,
          textStyle: {
            padding: [0, 5]
          }
        },

        // Add tooltip
        tooltip: {
          trigger: 'axis',
          padding: [10, 15],
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(0,0,0,0.025)'
            }
          }
        },

        // Horizontal axis
        xAxis: [{
          type: 'value',
          boundaryGap: [0, 0.01],
          minInterval: 1,
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              type: 'dashed'
            }
          }
        }],

        // Vertical axis
        yAxis: [{
          type: 'category',
          data: [this.$t('dashboard.statuses.orders.new'), this.$t('dashboard.statuses.orders.confirmed'), this.$t('dashboard.statuses.orders.sent_to_farm'), this.$t('dashboard.statuses.orders.in_preparation'), this.$t('dashboard.statuses.orders.ready_for_delivery'), this.$t('dashboard.statuses.orders.delivered')].reverse(),
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#eee']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
            }
          }
        }],

        // Add series
        series: [
          {
            name: this.$t('dashboard.customer_orders'),
            type: 'bar',
            itemStyle: {
              color: '#17393a'
            },
            data: []
          },
        ]
      },
      batches: {
        // Chart animation duration
        animationDuration: 750,

        // Setup grid
        grid: {
          left: 5,
          right: 30,
          top: 35,
          bottom: 0,
          containLabel: true
        },

        // Add legend
        legend: {
          data: [this.$t('dashboard.farmer_orders')],
          itemHeight: 8,
          itemGap: 20,
          textStyle: {
            padding: [0, 5]
          }
        },

        // Add tooltip
        tooltip: {
          trigger: 'axis',
          padding: [10, 15],
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(0,0,0,0.025)'
            }
          }
        },

        // Horizontal axis
        xAxis: [{
          type: 'value',
          boundaryGap: [0, 0.01],
          minInterval: 1,
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              type: 'dashed'
            }
          }
        }],

        // Vertical axis
        yAxis: [{
          type: 'category',
          data: [this.$t('dashboard.statuses.batches.new'), this.$t('dashboard.statuses.batches.confirmed'), this.$t('dashboard.statuses.batches.in_preparation'), this.$t('dashboard.statuses.batches.collection_ready'), this.$t('dashboard.statuses.batches.collected')].reverse(),
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#eee']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.015)']
            }
          }
        }],

        // Add series
        series: [
          {
            name: this.$t('dashboard.farmer_orders'),
            type: 'bar',
            itemStyle: {
              color: '#26a69a',
            },
            data: []
          },
        ]
      }
    }
  },
  mounted: function() {
    this.init();
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    }
  },
  methods: {
    confirmed()
    {
      switch (this.confirmationType) {
        case "general.cleanup":
          this.cleanupApplication();
          break;
      }
      this.closeConfirmation();
    },
    cleanupApplication: function () {
      let that = this;
      this.queueJob();
      this.axios.delete(this.$store.state.config.getCleanupApplicationUri()).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    onLoadingComplete: function () {
      this.updateOrderChart();
      this.updateBatchChart();

      if (this.initialise) {
        window.location.reload();
      }
    },
    init: function () {
      this.getOrderSummary();
      this.getBatchSummary();
    },
    getOrderSummary: function () {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getOrdersSummaryUri()).then(
          response => {
            let content = response.data;
            that.orderSummary = new OrderSummary(content.data);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    getBatchSummary: function () {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getBatchesSummaryUri()).then(
          response => {
            let content = response.data;
            that.batchSummary = new BatchSummary(content.data);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    updateOrderChart: function () {
      this.orders.series[0].data = this.orderSummary ? this.orderSummary.getFlattenStatusValues().reverse() : [];
      this.$refs.orderChart.refreshOption();
    },
    updateBatchChart: function () {
      this.batches.series[0].data = this.batchSummary ? this.batchSummary.getFlattenStatusValues().reverse() : [];
      this.$refs.batchChart.refreshOption();
    },
  }
}
</script>

<style scoped>
.charts {
  height: 20rem;
}
</style>